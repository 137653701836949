import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import Layout from '../../components/layout/layout';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import styles from './author.module.scss';
import ArticleCard from '../../components/article-card/article-card';

interface Props {
  data: any;
}

const AuthorTemplate = ({data}: Props) => {
  const author = get(data, 'contentfulAuthor');
  const articles = get(data, 'contentfulAuthor.article');
  const siteTitle = get(data, 'site.siteMetadata.title');
  return (
    <Layout>
      <div className={styles.author}>
        <Helmet title={`${author.alias} | ${siteTitle}`} />
        <h2>{`${author?.firstName} \"${author.alias}\" ${author?.lastName}`}</h2>
        {
            author.headshot ?
            <Img
            className={styles.headshot}
            alt={author.headshot.title}
            fluid={author.headshot.fluid}
          /> : ''
        }
        <div>{renderRichText(author.bio)}</div>
        <h3>{`${author.alias}'s Articles`}</h3>
        { articles > 0 ?
          articles.map((article: any) => {
            return(
              <ArticleCard article={article} />
            );
          }) : `${author.alias} doesn't have any writing yet, we are working on paying them enough juicy CPU cycles to produce some articles.`}
      </div>
    </Layout>
  )
};

export default AuthorTemplate;

export const pageQuery = graphql`
  query AuthorByAlias($alias: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAuthor(alias: { eq: $alias }) {
      alias
      firstName
      lastName
      bio {
          raw
      }
      headshot {
          title
          fluid(maxWidth: 1180, background: "rgb:000000", quality: 100) {
            ...GatsbyContentfulFluid
          }
      }
      article {
        title
        summary
        slug
        heroImage {
          title
          fluid(maxWidth: 1180, background: "rgb:000000", quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
